import React, { useRef, useEffect } from "react";
import {
  AspectRatio,
  Container,
  Flex,
  Heading,
  Text,
  Masonry,
  Button,
} from "../../components/core";
import { Download } from "../../components/svg";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";
import Page from "../../components/Page";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import { Card } from "../../components/Card";
import { InView } from "react-intersection-observer";
import { BackgroundVague, Vague, Player } from "../../components/svg";
import { useResponsiveValue } from "../../hooks/breakpoints";
import { getImage } from "gatsby-plugin-image";

const Presse = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const { theme } = useThemeUI();
  const masonryBreakpointCols = useResponsiveValue([1, 2, 3]);
  const masonryGutter = useResponsiveValue([1, 3, 3]);

  return (
    <Page sx={{ backgroundColor: "background3" }}>
      <SEO title="Espace presse" />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête presses"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" color="white">
                Espace presse
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Container sx={{ paddingTop: [8, null, 10] }}>
        <Box>
          <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
            {frontmatter.intro.title}
          </Heading>
          <Text>{frontmatter.intro.text}</Text>
          <Vague
            color="primary"
            sx={{
              marginTop: 5,
              marginBottom: [8, null, 0],
            }}
          />
        </Box>
      </Container>
      <BackgroundVague
        width="100%"
        height={null}
        sx={{
          transform: "scaleX(-1) rotate(180deg)",
          bottom: "-1px",
        }}
      />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "background",
          paddingTop: [7, null, 10],
          paddingBottom: [8, null, 11],
        }}
      >
        <Container
          variant="containerLg"
          sx={{
            zIndex: 1,
            position: "relative",
            textAlign: "center",
          }}
        >
          <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
            {frontmatter.kit_presse.title}
          </Heading>
          <Text preLine>{frontmatter.kit_presse.body}</Text>
          <Button
            as="a"
            to={frontmatter.kit_presse.link}
            external
            backgroundColor="secondary"
            sx={{
              width: "100%",
              maxWidth: "250px",
              textTransform: "uppercase",
              marginTop: 5,
              paddingLeft: 7,
              position: "relative",
            }}
          >
            <Download sx={{ position: "absolute", left: 3, top: "12px" }} />
            {frontmatter.kit_presse.btn}
          </Button>
        </Container>
      </Box>
      <BackgroundVague
        width="100%"
        height={null}
        sx={{
          transform: "scaleX(-1)",
        }}
      />
      <Container
        as="section"
        sx={{
          paddingTop: [8, null, 0],
          paddingBottom: 10,
        }}
        variant="containerLg"
      >
        <Box sx={{ textAlign: "center" }}>
          <Heading as="h2" variant="h1" color="secondary">
            Et bien d’autres
          </Heading>
          <Vague
            color="primary"
            sx={{
              marginTop: 5,
              marginBottom: [8, null, 11],
            }}
          />
        </Box>
        {!!frontmatter.cards.length > 0 && (
          <Masonry
            gutter={masonryGutter}
            breakpointCols={masonryBreakpointCols}
          >
            {
              frontmatter.cards.reduce(
                (result, { type, link, image, source, title, date }, index) => {
                  result.cards.push(
                    <InView key={index} triggerOnce threshold={0.4}>
                      {({ inView, ref }) => {
                        if (!image && inView) {
                          result.itemLightIndex++;
                        }

                        const getBackgroundColor = () => {
                          if (!image) {
                            if (result.itemLightIndex % 2 === 0) {
                              return "primary";
                            } else if (result.itemLightIndex % 3 === 0) {
                              return "secondary";
                            }
                          }
                          return "white";
                        };

                        return (
                          <Card
                            ref={ref}
                            variant="presse"
                            animate={inView ? "visible" : "hidden"}
                            variants={{
                              visible: { opacity: 1 },
                              hidden: { opacity: 0 },
                            }}
                            to={link}
                            externalLink
                          >
                            {image && (
                              <Card.Image
                                image={image.childImageSharp.gatsbyImageData}
                                imageLoading={"eager"}
                              >
                                {type === "Video" && (
                                  <Player
                                    sx={{
                                      zIndex: 10,
                                      opacity: 0.5,
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  />
                                )}
                              </Card.Image>
                            )}
                            <Card.Content
                              sx={{
                                padding: 3,
                                alignItems: "flex-start",
                              }}
                              backgroundColor={getBackgroundColor()}
                            >
                              <Text
                                variant="h6"
                                color={
                                  getBackgroundColor() !== "white"
                                    ? "white"
                                    : "primary"
                                }
                                fontWeight="bold"
                                uppercase
                              >
                                {source}
                              </Text>
                              <Heading
                                as="h3"
                                color={
                                  getBackgroundColor() !== "white"
                                    ? "white"
                                    : "secondary"
                                }
                                sx={{ marginTop: 1, marginBottom: 2 }}
                              >
                                {title}
                              </Heading>
                              <Text
                                color={
                                  getBackgroundColor() !== "white"
                                    ? "white"
                                    : "secondary"
                                }
                                fontWeight="semiBold"
                                sx={{ opacity: 0.7 }}
                              >
                                {date}
                              </Text>
                            </Card.Content>
                          </Card>
                        );
                      }}
                    </InView>
                  );

                  return result;
                },
                { cards: [], itemLightIndex: 0 }
              ).cards
            }
          </Masonry>
        )}
      </Container>
    </Page>
  );
};

export default Presse;

export const query = graphql`
  query PresseQuery {
    image1: file(relativePath: { eq: "troisieme_edition.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          layout: CONSTRAINED
          aspectRatio: 1.666
          width: 900
        )
      }
    }
    markdownRemark(fields: { slug: { eq: "/presse/" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        intro {
          title
          text
        }
        kit_presse {
          title
          btn
          body
          link
        }
        cards {
          type
          source
          link
          title
          date(formatString: "DD MMMM YYYY", locale: "fr")
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
