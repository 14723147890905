import React, { useState } from "react";
import { jsx, Box } from "theme-ui";
import { MotionCard, Card as CoreCard } from "../core";
import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import { navigate } from "../../helpers/navigate";

/** @jsx jsx */

const Card = React.forwardRef(
  ({ children, to, onClick, sx, externalLink, ...props }, ref) => {
    const [isHover, setIsHover] = useState(false);
    return (
      <MotionCard
        ref={ref}
        sx={{
          "&:hover": {
            cursor: to ? "pointer" : "initial",
          },
          "> div:first-of-type": {
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
          },
          "> div:last-of-type": {
            borderBottomLeftRadius: "inherit",
            borderBottomRightRadius: "inherit",
          },
          ...sx,
        }}
        whileHover={{
          boxShadow: to
            ? "0px 4px 50px rgba(0, 0, 0, 0.08)"
            : "0px 4px 50px rgba(0, 0, 0, 0)",
          transition: { duration: 0.2 },
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          to && navigate(to, { external: externalLink, blank: !!externalLink });
          onClick && onClick();
        }}
        {...props}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child, {
                isHover,
                setIsHover,
              })
            : child
        )}
      </MotionCard>
    );
  }
);

const Image = ({
  image,
  imageLoading = "lazy",
  alt = "Image card",
  isHover,
  setIsHover,
  sx,
  children,
  ...props
}) => (
  <Box sx={{ position: "relative", ...sx }} {...props}>
    <AnimatePresence>
      {isHover && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2,
            background:
              "linear-gradient(0deg, rgba(51, 189, 187, 0.4), rgba(51, 189, 187, 0.4))",
          }}
        />
      )}
    </AnimatePresence>
    <GatsbyImage
      image={image}
      alt={alt}
      style={{ width: "100%", display: "block" }}
      loading={imageLoading}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
    {children}
  </Box>
);

Card.Image = Image;
Card.Content = CoreCard.Content;

export { Card };
